import React, {FC} from 'react';
import {Helmet} from 'react-helmet';
import {Location} from '@reach/router';
import {HelmetHeaderProps} from '../types';

/* Getting random number for meta title */
// const randomNums = [0, 340, 224, 335, 214, 287, 246, 367, 291, 301, 236, 268, 341, 342, 247, 254, 386, 304, 268, 337, 381, 254, 348, 204, 269, 206, 266, 212, 218, 343]

// const getDate = () => {
//   let websitesNumber = 17834;
//   const date = new Date().getUTCDate();
//   for (let i = 0; i < date; i++) {
//     websitesNumber += randomNums[i];
//   }
//   return websitesNumber;
// };

const headerData = [
  {
    title: 'Why we built Hexowatch',
    description:
      "With 13 different page change monitoring types, Hexowatch makes it easy to track everything that's important to you on the internet.",
    path: '/about',
  },
  {
    title: 'Advanced Monitoring: Cut The Noise & Never Miss An Important Change',
    description:
      ' Hexowatch provides 13 different monitoring options to help you stay informed when specific changes occur on any website.',
    path: '/advanced-monitoring',
  },
  {
    title: 'Customer support contacts | Hexowatch',
    description: 'Customer support contacts - content change detection and alerting solution',
    path: '/contact',
  },
  {
    title: 'Cookie Policy | Hexowatch',
    description: 'This Cookie Policy describes how and why HEXACT use cookies',
    path: '/cookie-policy',
  },
  {
    title: 'Hexowatch FAQ',
    description: 'On this page you can get the answers to FAQs',
    path: '/faq',
  },
  {
    title: 'Hexowatch - forgot password',
    description: '',
    path: '/forgot-password',
  },
  {
    title: 'Hexowatch Login | Login To Your Account',
    description: 'Login to your dashboard on Hexowatch and start checking pages for changes.',
    path: '/login',
  },
  {
    title: 'Media Kit | Hexowatch logo and colors',
    description:
      'We share guidelines to help our customers use Hexowatch logo and colors without having to negotiate legal agreements',
    path: '/media-kit',
  },
  {
    title: 'Hexowatch Integration with Pabbly Connect',
    description:
      'Connect Hexowatch monitoring with Pabbly Connect to send change alerts to 100s of compatible apps on autopilot.',
    path: '/pabbly-connect',
  },
  {
    title: 'Content change detection and alerting solution Pricing packages',
    description:
      'Hexowatch is your AI sidekick to monitor any website for visual, content, source code or data changes',
    path: '/pricing',
  },
  {
    title: 'Limited time offer | Hexowatch',
    description:
      "Get Hexowatch's life-time deal with our pricing-promo for a limited time offer! Monitor any website for visual, content, source code, tech and price changes.",
    path: '/promo87282',
  },
  {
    title: 'Limited time offer | Hexowatch',
    description:
      "Get Hexowatch's life-time deal with our pricing-promo for a limited time offer! Monitor any website for visual, content, source code, tech and price changes.",
    path: '/promo14035',
  },
  {
    title: 'Content change detection and alerting solution Pricing packages',
    description:
      'Hexowatch is your AI sidekick to monitor any website for visual, content, source code or data changes',
    path: '/pricing_halloween',
  },
  {
    title: 'Privacy Policy | Hexowatch',
    description:
      'Your privacy is very important to us and we have created this privacy statement in order to demonstrate our commitment to you.',
    path: '/privacy-policy',
  },
  {
    title: 'Register for Hexowatch Account',
    description: 'Register in Hexowatch and get your own dashboard for monitoring any website for visual, content, source code or data changes.',
    path: '/registration',
  },
  {
    title: 'Hexowatch - reset password',
    description: '',
    path: '/reset-password',
  },
  {
    title: 'Public Roadmap and Development Plans',
    description:
      'This roadmap describes Hexowatch platform development plans for the benefit of its customers and users.',
    path: '/roadmap',
  },
  {
    title: 'Get Monitoring Changes in Slack',
    description:
      'Combine your Hexowatch monitorings with Slack to receive alerts via Slack whenever changes are detected.',
    path: '/slackbot',
  },
  {
    title: 'Software Integrations',
    description: 'List of 3rd party integrations with Hexowatch',
    path: '/software-integrations',
  },
  {
    title: 'Get Monitoring Changes in Telegram',
    description: 'Connect with Telegram to receive the monitoring notifications about your detected changes.',
    path: '/telegrambot',
  },
  {
    title: 'Get Monitoring Changes in Discord',
    description: 'Connect with Discord to receive the monitoring notifications about your detected changes.',
    path: '/discord',
  },
  {
    title: 'Terms of Service | Hexoatch',
    description:
      'These Terms of Service contain an Arbitration Agreement that may impact your rights to use the courts to resolve disputes.',
    path: '/terms-of-use',
  },
  {
    title: 'Hexowatch - verify email',
    description: '',
    path: '/verify-email',
  },
  {
    title: 'Hexowatch - verify notification email',
    description: '',
    path: '/verify-notification-email',
  },
  {
    title: 'API Documentation',
    description: 'This page provides documentation to get API keys for monitored URLs.',
    path: '/api-documentation',
  },
  {
    title: 'Hexowatch Integration with Zapier',
    description: 'Get changes from Hexowatch directly to your environment using Zapier',
    path: '/zapier',
  },
  {
    title: 'Hexowatch Integration with Microsoft Teams',
    description: 'Connect with Microsoft Teams to receive the monitoring notifications',
    path: '/microsoft-teams',
  },
  {
    title: 'Hexowatch Use Cases | How to Use Hexowatch',
    description: `Review Hexowatch use cases and get the full understanding of how you can monitor and track your or competitor's website at scale.`,
    path: '/use-cases',
  },
  {
    title: 'Monitor your website for security threats',
    description: '',
    path: '/use-cases/monitor-website-security-threads',
  },
  {
    title: 'Monitor Competitors | Competitors Monitoring',
    description: `Know what your competitors are doing. Monitor your competitors' price changes, new hires, product updates, and more with Hexowatch.`,
    path: '/use-cases/monitor-competitors',
  },
  {
    title: 'Find out when websites publish new requests for proposals & be the first to respond to RFP Alerts',
    description: '',
    path: '/use-cases/monitor-requests-for-proposals',
  },
  {
    title: 'Receive alerts when products become available or go out of stock',
    description: '',
    path: '/use-cases/monitor-products-availability',
  },
  {
    title: 'Monitor pages for legal & corporate compliance',
    description: '',
    path: '/use-cases/monitor-legal-compliance',
  },
  {
    title: 'Monitor Press Mentions',
    description: '',
    path: '/use-cases/monitor-press-mentions',
  },
  {
    title: 'How to track important changes on websites',
    description:
      'Tired of manual monitoring of your targeted websites? Hexowatch helps you automate tedious monitoring tasks with a few clicks. No code required.',
    path: '/use-cases/track-page-changes',
  },
  {
    title: 'Financial compliance | Financial monitoring',
    description:
      'Hexowatch can help you monitor your own website for the inclusion or exclusion of key text fragments, and the presence or absence of key terms.',
    path: '/use-cases/financial-compliance-monitoring',
  },
  {
    title: 'Receive alerts when event tickets go on sale',
    description: '',
    path: '/use-cases/know-when-event-tickets-sale',
  },
  {
    title: 'How to use Hexowatch',
    description: 'walkthrough videos that explain how to setup all our different monitor types.',
    path: '/how-to-use-hexowatch',
  },
  // monitorings
  {
    title: 'Visual monitor walkthrough tutorial',
    description: 'Monitor web pages for any visual changes. Select a part of the screen or the whole page.',
    path: '/visual-monitoring-walkthrough',
  },
  {
    title: 'HTML element monitor walkthrough tutorial',
    description: 'Monitor specific HTML elements or the content of any field for changes.',
    path: '/html-element-monitoring-walkthrough',
  },
  {
    title: 'HTML element monitor Advanced walkthrough tutorial',
    description: 'Monitor specific HTML elements or the content of any field for changes.',
    path: '/html-element-advanced-monitoring-walkthrough',
  },
  {
    title: 'How to monitor any website for keyword changes',
    description:
      'Keep an eye on specific keywords changes on any page with this Keyword monitoring tool. Ideal for affiliates, marketing teams and growth hackers.',
    path: '/keyword-monitoring-walkthrough',
  },
  {
    title: 'Technology monitor walkthrough tutorial',
    description: 'Monitor changes to the tech stack or 3rd party scripts used on the web page.',
    path: '/technology-monitoring-walkthrough',
  },
  {
    title: 'Source code monitor walkthrough tutorial',
    description: 'Monitor web pages for any source code/HTML modifications.',
    path: '/html-monitoring-walkthrough',
  },
  {
    title: 'Content monitoring walkthrough tutorial',
    description: 'Monitor the visible content on a web page for any changes.',
    path: '/content-monitoring-walkthrough',
  },
  {
    title: 'Automatic AI monitor walkthrough tutorial',
    description: 'Hexowatch will look for any types of changes (visual, content, source code and technology monitoring).',
    path: '/automatic-ai-monitoring-walkthrough',
  },
  {
    title: 'Availability monitor tutorial',
    description:
      'Monitor any page on your own websites, prospects, or affiliates. This Availability monitor keeps an eye on any page and notifies once they are available',
    path: '/availability-monitoring-walkthrough',
  },
  {
    title: 'Domain WHOIS monitoring tutorial',
    description:
      'Want to be the first to know when a domain name becomes available, or if a change of ownership occurs? Here is a Domain WHOIS monitor to get the job done.',
    path: '/domain-whois-monitoring-walkthrough',
  },
  {
    title: '',
    description: '',
    path: '/home',
  },
  {
    title: '',
    description: '',
    path: '/404',
  },
  {
    title: 'Website Change Detection, Monitoring & Archiving | Hexowatch',
    description:
      'Hexowatch is your AI sidekick to archive and monitor any website for visual, content, price, source code, technology, availability or WHOIS changes.',
    path: '/',
  },
  {
    title: 'Hexowatch – GDPR',
    // description: 'Register your account and get full access to the website tools.',
    path: '/gdpr',
  },
  {
    title: 'Meet the Hexact team',
    description:
      'The ones who see things differently, working tirelessly behind the scenes so you can change the world.',
    path: '/meet-the-team',
  },
  {
    title: 'Meet the Hexact team',
    description:
      'The ones who see things differently, working tirelessly behind the scenes so you can change the world.',
    path: '/meet-the-team-new',
  },
  {
    title: 'Become a Hexowatch Affiliate Partner | Affiliate Program ',
    description:
      'Become a Hexowatch affiliate partner and earn 20% commission on every sale and renewal. Earn commissions on all purchases made by new clients',
    path: '/affiliate',
  },
  {
    title: 'Webhook integration',
    // description: 'The ones who see things differently, working tirelessly behind the scenes so you can change the world.',
    path: '/webhook',
  },
  {
    title: 'Back to school promo | Hexowatch',
    // description: 'The ones who see things differently, working tirelessly behind the scenes so you can change the world.',
    path: '/back-to-school-promo',
  },
];

export default ({}: FC<HelmetHeaderProps>) => {
  return (
    <Location>
      {({location}) => {
        const item = headerData.find(data => {
          if (location.pathname[location.pathname.length - 1] === '/' && location.pathname !== '/') {
            return data.path === location.pathname.substr(0, location.pathname.length - 1);
          } else if (location.pathname === '/') {
            return data.path === location.pathname;
          } else {
            return location.pathname === data.path || location.pathname === data.path + '/';
          }
        });
        return (
          item && (
            <Helmet
              key={item.title}
              // link={[
              //   {
              //     rel: "canonical",
              //     href: `https://hexowatch.com${location.pathname}`,
              //   },
              // ]}
              title={item.title}
              // meta={[
              //   { name: 'description', content: item.description },
              //   { property: 'og:image', content: 'https://hexowatch.com/Hexowatch-AI-sidekick.png' },
              //   { property: 'og:url', content: `https://hexowatch.com${location.pathname}` },
              //   { property: 'og:title', content: item.title },
              //   { property: 'og:description', content: item.description },
              //   { property: 'og:type', content: 'website' },
              //   { property: 'og:locale', content: 'en_US' },
              //   { name: 'twitter:image', content: 'https://hexowatch.com/Hexowatch-AI-sidekick.png' },
              //   { name: 'twitter:title', content: item.title },
              //   { name: 'twitter:description', content: item.description },
              //   { name: 'twitter:card', content: 'summary_large_image' },
              //   { name: 'twitter:site', content: '@hexactinc' },
              // ]}
            >
              <html lang="en" />
              {!location.pathname.includes('/reset-password') && item.path !== '/reset-password' && item.path !== '/404' &&
                <script type="text/javascript">
                  {`
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-MQRTBZV');
                  `}
                </script>
              }
              {location.pathname.includes('/faq') && (
                <script type="application/ld+json">
                  {`{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "How do the checks work and how many pages can I check?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The amount of pages you can check depends on how frequently you need to check a page and the plan you purchase. For example: 1 page checked daily would consume 30 checks a month 1 page checked every 12 hours would consume 60 checks a month 1 page checked every hour would consume 720 checks a month 1 page checked every 10 minutes would consume 4320 checks a month
            
            Here is an example of how many checks you can make per plan:
            
            Pro plan (4,500 page checks/month):
            
            4500 pages checked monthly Or 1035 pages checked weekly Or 150 pages checked daily Or 75 pages checked every 12 hours Or 6 pages checked every hour Or 1 page every 15 minutes
            
            Business plan (10,000 page checks/month):
            
            10,000 pages checked monthly Or 2300 pages checked weekly Or 333 pages checked daily Or 166 pages checked every 12 hours Or 13 pages checked every hour Or 3 page every 15 minutes
            
            Business + plan (25,000 page checks/month):
            
            25,000 pages checked monthly Or 5753 pages checked weekly Or 833 pages checked daily Or 416 pages checked every 12 hours Or 34 pages checked every hour Or 8 pages every 15 minutes"
                }
              },{
                "@type": "Question",
                "name": "Does Hexowatch work for websites which are using a Javascript framework like React, Angular, Vue to render the HTML?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, websites which are using Javascript frameworks like React, Angular and Vue to render the HTML should work. But there is no absolute answer for all cases, so the best way is to open/preview the page/website with our tool, you can sign up for an account - if it opens as it should then yes if not contact our support team and we can check it out."
                }
              },{
                "@type": "Question",
                "name": "I’m in the B2B space, how can Hexowatch help me?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "There are many applications where you can use Hexowatch to free up time and stay ahead of the game for both B2C and B2B spaces. If you are in the B2B space, you can use Hexowatch to monitor price lists, suppliers, clients and be notified when anything changes. You can use it to follow public changelog pages and be notified when a new update is released for the software or product that you sell or use. If you have any competitors, you can monitor their homepage, news, or pricing pages for changes. Or if you have your own website, you can setup a few visual monitors on different pages, so that you can be alerted if any recent website update caused any visual issues or broke anything."
                }
              },{
                "@type": "Question",
                "name": "Can Hexowatch monitor a whole website for changes?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hexowatch monitors specific pages but also provides the ability to monitor URLs in bulk or entire sitemaps for changes."
                }
              },{
                "@type": "Question",
                "name": "What are the US / EU / Asia server locations for?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The business and above plan let you specify where the checks are coming from and you can pick US, EU, or Asia. Otherwise, it's from the US or another location based on load balancing availability at the moment the check is made.
            
            This feature enables you to check a website as if you were browsing from a specific geographic location, so for example, if you want to check a website that displays prices in USD for US visitors, you can specify that we should visit that web page from the USA."
                }
              },{
                "@type": "Question",
                "name": "Can we use this to be notified when limited edition items are added in a website/sold out size is back in stock - for example Jordan shoes?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, this is a popular use case for our non-business customers."
                }
              },{
                "@type": "Question",
                "name": "What is the data retention policy?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hexowatch stores collected data for a minimum of 3 months and you can export/download/copy any data during that period."
                }
              },{
                "@type": "Question",
                "name": "Can Hexowatch be used on websites that are password protected or have a member-only area?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hexowatch supports password protected websites via custom actions on the visual monitor and HTML element monitors
            
            Custom actions enable you to type login credentials and submit forms as part of the change monitoring flow to access any password protected page (as long as it is within their TOS)."
                }
              },{
                "@type": "Question",
                "name": "How is Hexowatch different from Hexometer?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hexowatch is more like a recon drone which lets you monitor any web page for changes, and you can use it to monitor how your pages display (for defacement protection or to be notified when an update has caused a visual bug) as well as use it to monitor your competitors, suppliers and just about any website.
            
            What makes Hexowatch really powerful is how you can specify what types of changes to look for, and how frequently you want to check these. For example, you can specify to be notified when a visual change is detected (in case a recent update caused a bug), or you can be alerted only when a price is updated when new content has been published, when a website adds a new script or changes their tech stack, or get a notification when a backlink partner stops linking back to you.
            
            All changes trigger a snapshot which is archived in the cloud for comparison and you can be notified by email or other notification channels connected when a change is detected."
                }
              },{
                "@type": "Question",
                "name": "What websites can I check?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hexowatch can check most website pages but is NOT designed to monitor social media networks (like Facebook, Instagram, LinkedIn, etc), search engines (like Google, Bing, etc), or large eCommerce platforms (like Amazon, eBay, etc)."
                }
              },{
                "@type": "Question",
                "name": "What if a page doesn’t display correctly when monitoring?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Some pages which use older coding standards or non-standard HTML or JS scripts can be problematic for our custom browser. If you encounter any issues, please get in touch with our team at  support@hexowatch.com so they can investigate and troubleshoot the issue."
                }
              },{
                "@type": "Question",
                "name": "What’s the difference between the plans?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "PRO provides 4500 checks per month, checks up to every 15 minutes, custom actions, webhooks and all our integrations.
            
            Business provides 10,000 checks per month, checks up to every 5 minutes as well as user-selectable server locations (to check pages as if you were in the US, EU or Asia)
            
            Business + provides all the above with 25,000 checks a month"
                }
              }]
            }`}
                </script>
              )}
              <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180-precomposed.png" />
              <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
              <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
              <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
              <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#03b8ff" />
              <link rel="canonical" href={`https://hexowatch.com${location.pathname}`} />
              <meta name="msapplication-TileColor" content="#03b8ff" />
              <meta name="msapplication-TileImage" content="/mstile-144x144.png" />
              <meta name="theme-color" content="#ffffff" />
              <meta name="msapplication-config" content="/browserconfig.xml" />

              <meta name="description" content={item.description} />
              <meta property="og:image" content={'https://hexowatch.com/Hexowatch-AI-sidekick.png'} />
              <meta property="og:url" content={`https://hexowatch.com${location.pathname}`} />
              <meta property="og:title" content={item.title} />
              <meta property="og:description" content={item.description} />
              <meta property="og:type" content="website" />
              <meta property="og:locale" content={'en_US'} />
              <meta name="twitter:image" content={'https://hexowatch.com/Hexowatch-AI-sidekick.png'} />
              <meta name="twitter:title" content={item.title} />
              <meta name="twitter:description" content={item.description} />
              <meta name="twitter:card" content={'summary_large_image'} />
              <meta name="twitter:site" content={'@hexactinc'} />
            </Helmet>
          )
        );
      }}
    </Location>
  );
};
