import ws from 'isomorphic-ws';
import fetch from 'isomorphic-fetch';
import React, {FC, useEffect} from 'react';
import {ApolloClient, HttpLink, ApolloLink, split, InMemoryCache} from 'apollo-boost';
import {WebSocketLink} from 'apollo-link-ws';
import {getMainDefinition} from 'apollo-utilities';
import {getUserToken, getRawCookie} from '../helpers';
import {ApolloProvider} from '@apollo/react-hooks';
import {globalHistory} from '@reach/router';

const wsurl = process.env.GATSBY_WS_HOST || 'ws://localhost:4000/api/ws';
const httpurl = process.env.GATSBY_HTTP_HOST || 'http://localhost:4000/api/ql';

const AuthMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({headers}: {headers: {}}) => ({
    headers: {
      ...headers,
      authorization: getUserToken(),
      device_key: getRawCookie('device_key') || '',
      hxroot: getRawCookie('root'),
    },
  }));

  return forward ? forward(operation) : null;
});

const wsLink = new WebSocketLink({
  uri: wsurl,
  options: {
    reconnect: true,
    connectionParams: () => ({
      token: getUserToken(),
      device_key: getRawCookie('device_key') || '',
    }),
  },
  webSocketImpl: ws,
});

const httpLink = new HttpLink({
  uri: httpurl,
  fetch,
  credentials: 'include',
});

const link = split(
  ({query}) => {
    let definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink,
);

export const GraphQLClient = new ApolloClient({
  link: ApolloLink.from([AuthMiddleware, link]),

  cache: new InMemoryCache({
    addTypename: false
  }),
});

export const ApolloWrapper: FC = ({children}) => {
  useEffect(() => {
    globalHistory.listen(({location}) => {
      if (window.ga) {
        const tracker = window.ga.getAll()[0];
        tracker.set('page', location.pathname + location.search);
        tracker.send('pageview');
      }
    });
  }, []);

  return <ApolloProvider client={GraphQLClient}>{children}</ApolloProvider>;
};
