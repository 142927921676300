import Cookies from 'js-cookie';
import md5 from 'md5';
import {navigate} from 'gatsby';
import moment from 'moment';

// ! add ip for ipad testing

const CookieDomains = ['37.186.119.181', '192.168.0.132', 'localhost', '.hexowatch.com'];

// TODO: REMOVE THIS!!!!!!!!
// TMP FIX FOR COOKIES
setTimeout(() => {
  // @ts-ignore
  if (
    global.window &&
    global.window.location &&
    global.window.location.hostname &&
    global.window.location.hostname.indexOf('hexowatch.com') > -1
  ) {
    Cookies.remove('__session', {
      domain: undefined,
    });
  }
}, 100);

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);
export const setRawCookie = (name: string, value: string) =>
  CookieDomains.map(domain => {
    Cookies.set(name, value),
      {
        expires: 30,
        domain: domain,
      };
  });

export const getCookies = (): any => {
  let cookiesStr: string | undefined = undefined;
  let cookies = {};
  for (let domain of CookieDomains) {
    cookiesStr = Cookies.get('__session', {domain: domain});
    if (cookiesStr) {
      try {
        cookies = JSON.parse(decodeURIComponent(cookiesStr));
      } catch (e) {}
    }
  }

  return cookies;
};

export const setCookie = (key: string, value: string | null | boolean) => {
  CookieDomains.map(domain => {
    Cookies.set('__session', JSON.stringify({...getCookies(), [key]: value}), {
      expires: 30,
      domain: domain,
    });
  });
};

export const setImpactClickIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__irClickId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setImpactMediaPartnerIdCookie = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('__mediaPartnerId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactClickIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__irClickId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactMediaPartnerIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__mediaPartnerId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setDocumentReferrer = (value: string | null) => {
  CookieDomains.map(domain => {
    Cookies.set('document_referrer', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeDocumentReferrer = () => {
  CookieDomains.map(domain => {
    Cookies.remove('document_referrer', {
      expires: 30,
      domain: domain,
    });
  });
};

export const getCookie = (key: string): string => getCookies()[key];
export const removeCookie = (key: string) => {
  const cookies = getCookies();
  delete cookies[key];
  CookieDomains.map(domain => {
    Cookies.remove('__session', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setDeviceKey = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('device_key', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setCookieDownloadIgnore = () => setCookie('download-panel', 'closed');
export const getCookieDownloadIgnore = () => getCookie('download-panel');

export const setCaptchaResponseToken = (token: string) =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.setItem('toolsCaptchaResponseToken', token);

export const getCaptchaResponseToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.getItem('toolsCaptchaResponseToken');

export const deleteCaptchaResponseToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.removeItem('toolsCaptchaResponseToken');

export const setToolsToken = (token: string) =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.setItem('toolsSessionToken', token);

export const getToolsToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.getItem('toolsSessionToken');

export const deleteToolsToken = () =>
  // @ts-ignore
  global.window && sessionStorage && sessionStorage.removeItem('toolsSessionToken');

export const setShowModal = (show: string) => localStorage.setItem('show-modal', show);
export const getShowModal = (): string => localStorage.getItem('show-modal') || 'true';
export const removeShowModal = () => localStorage.removeItem('show-modal');

export const setShowDemoModalonExit = (show: string) => localStorage.setItem('show-demo-modal-onexit', show);
export const getShowDemoModalonExit = (): string => localStorage.getItem('show-demo-modal-onexit') || 'true';
export const removeShowDemoModalonExit = () => localStorage.removeItem('show-demo-modal-onexit');

export const setLTDPromo329 = (show: string) => localStorage.setItem('ltd-329', show);
export const getLTDPromo329 = (): string => localStorage.getItem('ltd-329') || '';
export const removeLTDPromo329 = () => localStorage.removeItem('ltd-329');

export const setShowAppFooter = (show: string) => localStorage.setItem('extension_popup_closed', show);
export const getShowAppFooter = (): string | null => localStorage.getItem('extension_popup_closed');

export const setUserExists = (show: string) => localStorage.setItem('user-exists', show);
export const getUserExists = (): string => localStorage.getItem('user-exists') || 'false';
export const removeUserExists = () => localStorage.removeItem('user-exists');

export const setHidePromoHeader = () => setCookie('hide-promo-header', 'true');
export const getHidePromoHeader = (): string => getCookie('hide-promo-header');
export const removeHidePromoHeader = () => removeCookie('hide-promo-header');

export const setPromoHeader = () => setCookie('holidays-promo-header', 'true');
export const getPromoHeader = (): string => getCookie('holidays-promo-header');
export const removePromoHeader = () => setCookie('holidays-promo-header', '');

export const setPremiumAnnualModal = () => setCookie('premium-credits-annual', 'true');
export const getPremiumAnnualModal = (): string | undefined => getCookie('premium-credits-annual');
export const removePremiumAnnualModal = () => removeCookie('premium-credits-annual');

export const setUserToken = (token: string) => setCookie('user-token', token);
export const setUserHash = (hash: string) => setCookie('user-hash', hash);
export const getUserToken = (): string | undefined => getCookie('user-token');
export const getUserHash = (): string | undefined => getCookie('user-hash');
export const removeUserToken = () => setCookie('user-token', null);
export const removeUserHash = () => setCookie('user-hash', null);

export const setNewUser = (isNew: boolean) => setCookie('new-user', isNew);
export const getNewUser = (): string | undefined => getCookie('new-user');
export const removeNewUser = () => removeCookie('new-user');

export const setUserEmail = (email: string) => setCookie('user-email', email);
export const getUserEmail = (): string | undefined => getCookie('user-email');
export const removeUserEmail = () => setCookie('user-email', null);

export const setUserName = (name: string) => setCookie('user-name', name);
export const getUserName = (): string | undefined => getCookie('user-name');
export const removeUserName = () => setCookie('user-name', null);

export const setCookiePolicy = () => setCookie('user', 'registered');
// @ts-ignore
export const getCookiePolicy = () => (!global.window ? 'registered' : getCookie('user'));

export const removeCookiePolicyNew = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicyNew = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

export const setRefreshToken = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('ref_token', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

// export const getGravatar = (email: string, size?: number) => {
//   size = size || 80;
//   return 'https://www.gravatar.com/avatar/' + md5(email) + '?s=' + size + '&d=monsterid';
// };

export const toggleLastURL = () => {
  if (window.history.length === 1) {
    navigate('/');
  } else {
    window.history.back();
  }
};

export const toggleCloseLoginModalURL = () => {
  navigate('/');
};

export const gotToWithReplace = (pathName: string) => {
  window.history.replaceState({}, '', pathName);
  if (pathName === '/login') {
    navigate(pathName, {state: {goBack: true}});
  } else {
    navigate(pathName);
  }
};

export const logOut = () => {
  setUserExists('true');
  removeUserToken();
  removeUserHash();
  removeUserEmail();
  removeUserName();
  // removeNewUser();
  removePremiumAnnualModal();
  if (!getUserName()) {
    navigate('/');
  }
};

export const getParsedDateAndTimeGMT = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment(parsedDate).format('MMM DD, YYYY HH:mm:ss') : '';
};

export const removePackageInfo = () => {
  localStorage.removeItem('fromLogonOrRegistrationPage');
  localStorage.removeItem('checkout-data');
};
export const getPromoDate = () => {
  // Get current date and time in GMT
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
  const currentHour = currentDate.getUTCHours();

  // Calculate time remaining until the next Friday 11 AM GMT
  let daysUntilFriday = (5 - currentDay + 7) % 7; // 5 is the day index for Friday
  if (currentDay === 5 && currentHour >= 7) {
    // If it's already Friday after 11 AM, consider the next Friday
    daysUntilFriday += 7;
  }

  // Calculate the next Friday's date
  const nextFriday = new Date(currentDate);
  nextFriday.setUTCDate(currentDate.getUTCDate() + daysUntilFriday);
  nextFriday.setUTCHours(7, 0, 0, 0); // Set time to 11 AM GMT

  return nextFriday.toUTCString();
};