import './src/styles/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import HelmetTitle from './src/components/helmet';
import { ApolloWrapper } from './src/graphql';

export const wrapRootElement = ({ element }) => {
  return <ApolloWrapper>{element}</ApolloWrapper>;
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <HelmetTitle />
      {element}
    </>
  );
};
